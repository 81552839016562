import React, { useState, useRef } from "react";
import logo from "../../assets/menemenLogo.png";

const Navbar = () => {
  const [menemenIsOpen, setMenemenIsOpen] = useState(false);
  const [kurumsalIsOpen, setKurumsalIsOpen] = useState(false);
  const [hizmetlerimizIsOpen, setHizmetlerimizIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMenemenMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
    setMenemenIsOpen(true);
  };

  const handleMenemenMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenemenIsOpen(false);
    }, 35);
  };
  const handleKurumsalMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
    setKurumsalIsOpen(true);
  };

  const handleKurumsalMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setKurumsalIsOpen(false);
    }, 35);
  };
  const handleHizmetlerimizMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
    setHizmetlerimizIsOpen(true);
  };

  const handleHizmetlerimizMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHizmetlerimizIsOpen(false);
    }, 35);
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-white bg-opacity-75 z-10">
      <div className="p-2">
        <div className="flex flex-row items-center justify-between mx-16">
          <div className="flex flex-row items-center">
            <img src={logo} className="w-24" alt="Menemen Logo" />
            <p className="ml-3 text-4xl">Menemen Belediyesi</p>
          </div>
          <div className="flex flex-row gap-x-8 items-center">
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Anasayfa
            </div>
            <div
              className="relative"
              onMouseEnter={handleMenemenMouseEnter}
              onMouseLeave={handleMenemenMouseLeave}
            >
              <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
                Menemen
              </div>
              {menemenIsOpen && (
                <div
                  className="absolute left-0 mt-1 bg-white shadow-md rounded-md p-2 bg-opacity-75 hover:bg-white"
                  onMouseEnter={handleMenemenMouseEnter}
                  onMouseLeave={handleMenemenMouseLeave}
                >
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/38/tarihce">
                      Tarihçe
                    </a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/39/kubilay-olayi">Kubilay Olayı</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/41/ilce-haritasi">İlçe Haritası</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/ListeSayfa/42/hastaneler">Hastaneler</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/43/koylerimiz">Köylerimiz</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/ImgListe/45/piknik-alanlarimiz">Piknik Alanlarımız</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Tematik Çocuk Oyun Köyü</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Menemen Doğal Güzellikleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Menemen Tarihi Değerleri</a>
                  </div>
                </div>
              )}
            </div>
            <div
              className="relative"
              onMouseEnter={handleKurumsalMouseEnter}
              onMouseLeave={handleKurumsalMouseLeave}
            >
              <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
                Kurumsal
              </div>
              {kurumsalIsOpen && (
                <div
                  className="absolute left-0 mt-1 bg-white shadow-md rounded-md p-2 bg-opacity-75 hover:bg-white"
                  onMouseEnter={handleKurumsalMouseEnter}
                  onMouseLeave={handleKurumsalMouseLeave}
                >
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/151/misyon">
                      Misyon Vizyon
                    </a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/4/ozgecmis">Başkan</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/49/mudurlukler">Müdürlükler</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/meclis-uye-listesi">Meclis Üyeleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/meclis-kararlari">Meclis Kararları</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/meclis-gundemleri">Meclis Gündemleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/261/stratejik-plan-2020-2024">Stratejik Planlar</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/51/encumen-uyeleri">Encümen Üyeleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/99/muhtarliklar">Muhtarlıklar</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>KVKK</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Komisyon Üyeleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Performans Raporları</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Faaliyet Raporları</a>
                  </div>
                </div>
              )}
            </div>
            <div
              className="relative"
              onMouseEnter={handleHizmetlerimizMouseEnter}
              onMouseLeave={handleHizmetlerimizMouseLeave}
            >
              <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
                Hizmetlerimiz
              </div>
              {hizmetlerimizIsOpen && (
                <div
                  className="absolute left-0 mt-1 bg-white shadow-md rounded-md p-2 bg-opacity-75 hover:bg-white"
                  onMouseEnter={handleHizmetlerimizMouseEnter}
                  onMouseLeave={handleHizmetlerimizMouseLeave}
                >
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/ImgListe/90/sosyal-yardimlar">Sosyal Yardımlar</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/87/saglik-hizmetleri">Sağlık Hizmetleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/86/veterinerlik-hizmetleri">Veterinerlik Hizmetleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/ImgListe/88/temizlik-hizmetleri">Temizlik Hizmetleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/89/zabita-hizmetleri">Zabıta Hizmetleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/85/cenaze-defin-hizmetleri">Cenaze Defin Hizmetleri</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/Sayfa/81/evlendirme-memurlugu">Evlendirme Memurluğu</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a href="https://www.menemen.bel.tr/ImgListe/54/is-yeri-acma-ve-calistirma-ruhsati">İş yeri Açma ve Çalıştırma Ruhsatı</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Vergi Takvimi</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>İmar ve Şehircilik Müdürlüğü</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>Acil Toplanma Alanları & İrap</a>
                  </div>
                  <div className="pr-10 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer  hover:text-customNavbarTextColor">
                    <a>İşkur Hizmet Noktası</a>
                  </div>
                </div>
              )}
            </div>

           
            
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Projeler
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Etkinlikler
            </div>
           
            <a
              href="https://www.menemen.bel.tr/menemen-iletisim"
              className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer"
            >
              İletişim
            </a>
            <div className="flex w-96 rounded bg-white">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Size nasıl yardımcı olabiliriz?"
                className="w-full border-2 bg-transparent p-4 text-gray-900 rounded-md"
              />
              <button className="m-2 rounded bg-[#9cbf95] px-5 py-2 text-[#ffffff] absolute left-left91 top-top5">
                Ara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
